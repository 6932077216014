




























import Vue from "vue";
import { Article } from "@/core/models";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ArticleItem extends Vue {
  @Prop({ default: () => {} }) article!: Article;
  @Getter("getUserEmail", { namespace: "profile" }) userEmail!: string;

  get canEdit() {
    let admins = ["demo@prezpvr.de", "dev@prezpvr.de"]; // only these two can edit!
    return admins.includes(this.userEmail);
  }
  get body() {
    const text = this.article.Body;
    if (text.length < 200) return text;
    const section = text.substr(0, 200);
    return `${section}...`;
  }
  get image() {
    if (this.article.BannerImage) return this.article.BannerImage.URI;
    return "";
  }

  openArticle(): void {
    this.$router.push("/news/article/" + this.article.ID);
  }
}
